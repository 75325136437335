@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


/* all text poppins */
body {
  font-family: 'Poppins', sans-serif;
  color: #fff;
}

/* light mode white text */
h1, h2, h3, h4, h5, h6, p, a, li, span, label, input, textarea, button {
  color: #fff;
}


#root {
  height: 100vh;
}

.App {
  height: 100%;
}